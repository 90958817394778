<template>
  <div class="content-wrapper__layout">
    <side-nav></side-nav>
    <div class="content-wrapper__main-content-wrapper content-wrapper__main-content-wrapper--31 content-wrapper__main-content-wrapper--31--home" :ref="'container'">
      <section class="main-content main-content--no-bottom">
        <router-link v-if="$store.state.user.id" :to="{name: 'NewPost'}" class="btn btn--primary btn--fixed mobile-only"><img class="icon" src="@/assets/images/icons/ico_add--wh.svg"> Nueva publicación</router-link>
        <div class="section">
          <div class="section__titles">
            <h5 class="section__title hidden-sm text-alt">Últimas publicaciones</h5>
            <div class="form__group form__group--sm form__group--auto-width">
              <select class="form__input" name="" id="" v-model="sortOrder" @change.prevent="sortEntries()">
                <option :value="null" selected disabled>Ordenar por</option>
                <option value="default">Últimas publicaciones</option>
                <option value="lastMonth">Publicaciones más populares en el último mes</option>
                <option value="allTime">Publicaciones más comentadas</option>
              </select>
            </div>
          </div>
          <div class="post-list">
            <Loader v-for="index in perPage" :key="index" viewBox="0 0 300 200">
              <rect x="0" y="0" rx="3" ry="3" width="250" height="30" />
              <rect x="20" y="40" rx="3" ry="3" width="260" height="15" />
              <rect x="20" y="70" rx="3" ry="3" width="260" height="15" />
              <rect x="20" y="100" rx="3" ry="3" width="170" height="15" />
            </Loader>
            <template v-if="!loading">
              <div class="listed-post" v-for="post in posts" :key="post.id">
                <div class="post__header">
                  <div class="post__author">
                    <router-link :to="{name: 'Profile', params: {id: post.author.id}}">
                      <div class="user__image" v-if="post.author?.profilePicture">
                        <img referrerpolicy="no-referrer" :src="post.author.profilePicture">
                      </div>
                      <div class="user__image" v-else>
                        <img src="@/assets/images/avatar_default.png">
                      </div>
                    </router-link>
                    <div class="user__data">
                      <h5>
                        <router-link :to="{name: 'Profile', params: {id: post.author.id}}" >
                          {{post.author.fullName}}
                        </router-link>
                      </h5>
                      <p class="post__time caption text-alt" :title="$moment.utc(post.createdAt).local().format('L')">
                        <router-link :to="{name: 'Post', params: {id: post.id}}">
                          {{$moment.utc(post.createdAt).fromNow()}}
                        </router-link>
                      </p>
                    </div>
                  </div>
                  <div class="post__options">
                    <DropdownMenu>
                      <template #button>
                        <button class="btn btn--icon btn--circle btn--xs"><img class="icon" src="@/assets/images/icons/ico_dots--bl.svg"></button>
                      </template>
                      <template #options>
                        <a class="dropdown__option" href="#" @click.prevent="displayShareDialog(post)">
                          Compartir
                        </a>
                        <a v-if="!post.locked && (post.author.id == $store.state.user.id || $store.state.user.role_id == 1)" class="dropdown__option" href="#" @click.prevent="lockQna(post)">
                            No permitir más comentarios en esta publicación
                        </a>
                        <a v-else-if="post.locked && (post.author.id == $store.state.user.id || $store.state.user.role_id == 1)" class="dropdown__option" href="#" @click.prevent="lockQna(post)">
                            Volver a permitir comentarios en esta publicación
                        </a>
                      </template>
                    </DropdownMenu>
                  </div>
                </div>
                <div class="post__main-content">
                  <div class="post__causes-and-areas">
                    <img src="@/assets/images/icons/ico_book--gray.svg">
                    <div class="post__causes">
                      <template v-for="cause in post.causes" :key="cause.id">
                        <span class="caption post__cause">{{cause.name}}</span>
                      </template>
                      <template v-for="area in post.areas" :key="area.id">
                        <span class="caption post__cause">{{area.name}}</span>
                      </template>
                    </div>
                  </div>
                  <div class="post__titles">
                    <div class="post__type">
                      <div v-if="post.entryType === 1" class="tag">
                        <img class="icon" src="@/assets/images/icons/ico_question--white.svg">
                      </div>
                      <div v-else class="tag">
                        <img class="icon" src="@/assets/images/icons/ico_article--white.svg">
                      </div>
                    </div>
                    <div class="post__title">
                      <router-link :to="{name: 'Post', params: {id: post.id}}" >
                        <h4>{{post.title}}</h4>
                      </router-link>
                    </div>
                  </div>
                  <div v-if="post.tags" class="post__tags">
                    <span class="tag tag--primary post__tag" v-for="tag in post.tags" :key="tag.id">
                      <router-link :to="{name: 'Filter', params: {filterType: 'etiquetas', filterId: tag.id} }">
                        {{tag.name}}
                      </router-link>
                    </span>
                  </div>
                </div>
                <div class="post__cover-image post__cover-image--centered" v-if="post.fileUrl">
                  <img :src="post.fileUrl" />
                </div>
                <div class="post__footer">
                  <div class="post__interactions-side">
                    <div class="post__interaction">
                      <button type="button" :disabled="post.savingVote" class="btn btn--primary btn--text btn--sm" @click.prevent="vote(post)" v-if="$store.state.user.id">
                        <template v-if="post.voted">
                          <img class="icon" src="@/assets/images/icons/ico_heart_filled--orange.svg"/> {{post.upvoteCount}}
                        </template>
                        <template v-else>
                          <img class="icon" src="@/assets/images/icons/ico_heart--orange.svg"/> {{post.upvoteCount}}
                        </template>
                      </button>
                      <button v-else type="button" class="btn btn--primary btn--text btn--sm" @click.prevent="notSignedIn()">
                        <img class="icon" src="@/assets/images/icons/ico_heart_filled--orange.svg"/> {{post.upvoteCount}}
                      </button>
                    </div>
                    <div class="post__interaction">
                      <button class="btn btn--primary btn--text btn--sm">
                        <router-link :to="{name: 'Post', params: {id: post.id}}" >
                          <img class="icon" src="@/assets/images/icons/ico_comment--orange.svg"> {{post.commentCount}} {{post.entryType === 1 ? 'Respuestas' : 'Comentarios'}}
                        </router-link>
                      </button>
                    </div>
                  </div>
                  <div class="post__interactions-side">
                    <div class="post__interaction">
                      <button class="btn btn--icon btn--circle">
                        <img class="icon" src="@/assets/images/icons/ico_share--orange.svg" @click.prevent="displayShareDialog(post)">
                      </button>
                    </div>
                  </div>
                </div>

                
                <!-- <div v-if="post.causes.length">
                  <strong>Causas:</strong>
                  <div v-for="cause in post.causes" :key="cause.id">
                    {{cause.name}}
                  </div>
                </div>
                <div v-if="post.areas.length">
                  <strong>Areas:</strong>
                  <div v-for="area in post.areas" :key="area.id">
                    {{area.name}}
                  </div>-->
              </div>
            </template>
            <Loader v-for="index in perPage" :key="index" viewBox="100 100 300 200">
              <rect x="0" y="0" rx="3" ry="3" width="250" height="30" />
              <rect x="20" y="40" rx="3" ry="3" width="260" height="15" />
              <rect x="20" y="70" rx="3" ry="3" width="260" height="15" />
              <rect x="20" y="100" rx="3" ry="3" width="170" height="15" />
            </Loader>
          </div>
          <div class="post-list__end" v-if="this.posts.length == this.total">
            <!-- <img src="@/assets/images/illustrations/ill_checklist.png" alt="En la imagen un listado de tareas completado"> -->
          </div>
        </div>
      </section>
      <section class="extra-content extra-content--areas-and-causes-container">
        <div class="extra-content--areas-and-causes">
          <template v-if="popularAreas.length > 0">
            <div class="extra-content__title" @click.prevent="toggleAreas()">
              <h5>Áreas de interés</h5>
              <img class="icon" v-if="areasIsOpen" src="@/assets/images/icons/ico-chev-up--orange.svg">
              <img class="icon" v-else src="@/assets/images/icons/ico-chev-down--orange.svg">
            </div>
            <div class="extra-content__list" v-if="areasIsOpen">
              <router-link class="grid grid-2 extra-content__list-item" v-for="(area, index) in popularAreas" :key="index" :to="{name: 'Filter' , params:{ filterType: `areas-de-interes` , filterId: area.id }}">
                <div class="dot"></div>
                <p class="caption">{{ area.name }}</p>
                <div class="text-right">
                  <span class="tag tag--primary post__tag">{{ area.total }}</span>
                </div>
              </router-link>
            </div>
          </template>
          <br>
          <template v-if="popularCauses.length > 0">
            <div class="extra-content__title" @click.prevent="toggleCauses()">
              <h5>Causas</h5>
              <img class="icon" v-if="causesIsOpen" src="@/assets/images/icons/ico-chev-up--orange.svg">
              <img class="icon" v-else src="@/assets/images/icons/ico-chev-down--orange.svg">
            </div>
            <div class="extra-content__list" v-if="causesIsOpen">
              <router-link class="grid grid-2 extra-content__list-item" v-for="(cause, index) in popularCauses" :key="index" :to="{name: 'Filter' , params:{ filterType: `causas` , filterId: cause.id }}">
                <div class="dot"></div>
                <p class="caption">
                  {{ cause.name }}
                </p>
                <div class="text-right">
                  <span class="tag tag--primary post__tag">{{ cause.total }}</span>
                </div>
              </router-link>
            </div>
          </template>
        </div>
      </section>
    </div>
  </div>
  <share-dialog v-if="showShareDialog" :sharing="sharing" @close="showShareDialog = false"></share-dialog>
  <register-dialog v-if="showRegisterDialog" @close="showRegisterDialog = false"></register-dialog>
</template>

<script>
import SideNav from '@/components/SideNav.vue'
import { ContentLoader as Loader } from 'vue-content-loader'
import ShareDialog from '@/components/ShareDialog.vue'
import DropdownMenu from '@/components/DropdownMenu.vue'
import RegisterDialog from '@/components/RegisterDialog.vue'

export default {
  components: { 
    SideNav, 
    Loader, 
    ShareDialog, 
    DropdownMenu,
    RegisterDialog
  },
  name: 'Home',
  data(){
    return{
      posts: [],
      loading: true,
      perPage: 15,
      perPageCopy: 15,
      page: 1,
      loadingMorePosts: false,
      total: null,
      showShareDialog: false,
      showRegisterDialog: false,
      sharing: {},
      sortOrder: 'default',
      notificationsSupported: false,
      popularAreas: [],
      popularCauses: [],
      areasIsOpen: true,
      causesIsOpen: true
    }
  },
  async mounted() {
    this.$nextTick(() => {
        window.addEventListener('scroll', this.onScroll);
    })
    this.total = (await this.$axios.get('Qna/total')).data
    const res = await this.loadPosts()
    this.loading = false
    this.perPage = 0
    this.page = 2
    this.posts = res

    const areas = (await this.$axios.get('Areas')).data
    const causas = (await this.$axios.get('Causas')).data
    this.popularAreas = areas.filter(item => item).sort((a , b) => {return b.total - a.total})
    this.popularCauses = causas.filter(item => item).sort((a , b) => {return b.total - a.total})
  },
  created() {
    if('Notification' in window && 'serviceWorker' in navigator) {
      this.notificationsSupported = true
    }
  },
  beforeUnmount() {
    this.$nextTick(() => {
        window.removeEventListener('scroll', this.onScroll);
    })
  },
  methods: {
    async sortEntries() {
      this.perPage = 15
      this.posts.length = 0
      this.loading = true
      this.page = 1
      this.total = (await this.$axios.get('Qna/total')).data
      const res = await this.loadPosts()
      this.loading = false
      this.perPage = 0
      this.page = 2
      this.posts = res
    },
    async loadPosts() {
      return (await this.$axios.get(`Qna/${this.page}/${this.sortOrder}`)).data
    },
    async onScroll() {
      if(this.$refs.container.getBoundingClientRect().bottom < window.innerHeight + 300 && !this.loadingMorePosts && this.posts.length < this.total){
        this.loadingMorePosts = true
        this.perPage = this.perPageCopy
        const res = await this.loadPosts()
        this.page = this.page + 1
        this.perPage = 0
        this.posts.push(...res)
        this.loadingMorePosts = false
      }
    },
    async vote(post) {
      post.savingVote = true
      const res = (await this.$axios.post('Qna/vote', {post_id: post.id}))
      if(res.status == 200){
        post.voted = res.data.voted
        post.upvoteCount = res.data.upvoteCount
      }
      post.savingVote = false
    },
    displayShareDialog(post){
      this.showShareDialog = true
      this.sharing.url = `${window.location.origin}/publicacion/${post.id}`
      this.sharing.title = post.title || post.description
      //this.sharing.description = post.description
      //this.sharing.quote = 'quote'
      this.sharing.hashtags = post.tags.map(n => n.name).join(',')
      //this.sharing.twitterUser = 'twitterUser'
    },
    lockQna(post){
      if(confirm(`¿Está seguro de que desea ${post.locked ? 'desbloquear' : 'bloquear'} la ${post.entryType === 1 ? 'Pregunta' : 'Publicación'}?`)){
        this.$axios.post('Qna/lock', {qna_id: post.id}).then(res => {
          if(res.status == 200){
            post.locked = !post.locked
            this.$toast.success(`${post.entryType === 1 ? 'La pregunta' : 'La publicación'} ${!post.locked ? 'ha sido desbloqueada exitosamente' : 'ha sido bloqueada exitosamente'}`)	
          }
        })
      }
    },
    closeRegisterModal() {
      this.showRegisterDialog = false
    },
    notSignedIn(){
      this.showRegisterDialog = true
    },
    toggleAreas() {
      this.areasIsOpen = !this.areasIsOpen
    },
    toggleCauses() {
      this.causesIsOpen = !this.causesIsOpen
    }
  }
}
</script>